import React, { FC, useState } from "react";

interface Props {
    pathToFile: string
}

const DownloadComponent: FC<Props> = ({pathToFile}) => {
    const [fileSize, setFileSize] = useState(0);
    let fileName = "";
    if (pathToFile !== "") {
        fileName = pathToFile.split("/")[pathToFile.split("/").length-1];
        fetch(pathToFile, {
            method: 'HEAD'
        })
            .then((response) => {
                //@ts-ignore
                setFileSize(parseInt(response.headers.get("content-length"))/1000);
            })
            .catch((error) => console.log("Fetch error!", error));
    }

    let anchorStyles: any = {
        flexGrow: 1,
        height: '4rem',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'black'
    };

    if (pathToFile === "") {
        anchorStyles["pointerEvents"] = 'none';
        anchorStyles["color"] = "grey";
    }

    return (
        <div style={{marginBottom: '1rem'}} className="form-column">
            <label>Tätigkeitsübersicht</label>
            <a
                href={pathToFile !== "" ? pathToFile : "javascript:void(0)"}
                target="_blank"
                download={fileName}
                style={anchorStyles}
            >
                <div style={{width: '15%', display: 'flex', justifyContent: 'center'}}>
                    <img src="/images/onejob/PaperIcon.png" alt="Matthäi" />
                </div>
                <div style={{width: '70%'}}>
                    <span>
                        <b>
                            {pathToFile === "" ?
                                "Tätigkeitsübersicht" : fileName.replaceAll("_", " ").replace(".pdf", "")
                            }
                        </b>
                    </span>
                    <br />
                    {pathToFile !== "" && <span style={{color: '#909090'}}>PDF&nbsp;&nbsp;{Math.round(fileSize)}KB</span>}
                </div>
                <div style={{width: '15%', display: 'flex', justifyContent: 'center'}}>
                    <img src="/images/onejob/DownloadIcon.png" alt="Matthäi" />
                </div>
            </a>
        </div>
    );
}

export default DownloadComponent;