import React, { FC, useState, useEffect } from "react";
import { ReactComponent as IconRemove } from "../../styles/icons/RemoveIcon.svg";

interface Props {
    title: string,
    options: any[],
    values?: any,
    isRequired?: boolean
    name?: any,
    handler?: React.EventHandler<any>,
    disabled?: boolean,
    checked?: boolean,
    renderAddability?: boolean,
    asSwitch?: boolean,
    style?: any,
    isValid?: any,
    initialOptions?: any,
    isSummary?: boolean
}

const CheckboxGroup: FC<Props> = (
    {
        title,
        options,
        values,
        isRequired,
        name,
        handler,
        disabled,
        checked,
        renderAddability,
        asSwitch,
        style,
        isValid,
        initialOptions,
        isSummary
    }
) => {
    if (typeof isValid === "undefined") {
        isValid = true;
    }
    const inputRef: any = React.createRef();
    const [newOptions, setNewOptions] = useState<any>([]);
    const [localOptions, setLocalOptions] = useState(initialOptions ? initialOptions : options);
    useEffect(() => {
        if (initialOptions) {
            let currentNewOptions = [...newOptions];
            options.forEach((value: any) => {
                if (!localOptions.includes(value) && !currentNewOptions.includes(value)) {
                    currentNewOptions.push(value);
                }
            });
            setNewOptions(currentNewOptions);
            setLocalOptions([...localOptions, ...currentNewOptions]);
        }
    }, []);

    const [isInvalid, setIsInvalid] = useState(false);
    const addNewOption = () => {
        const value = inputRef.current.value;
        if (!localOptions.includes(value) && value !== "") {
            delegateChange(value, true);
            let currentNewOptions = [...newOptions];
            currentNewOptions.push(value);
            setNewOptions(currentNewOptions);

            if (!options.includes(value)) {
                options.push(value);
            }

            let currentLocalOptions = [...localOptions];
            currentLocalOptions.push(value);
            setLocalOptions(currentLocalOptions);

            setIsInvalid(false);
            inputRef.current.value = "";
        } else {
            setIsInvalid(true);
        }
    };
    let inputClasses = "";
    if (asSwitch) {
        inputClasses += "custom-control-input";
    } else {
        inputClasses += "form-control";
    }

    let spanClasses = disabled ? 'checkmark disabled' : 'checkmark';
    if (typeof isValid !== "undefined" && !isValid) {
        spanClasses += !isValid ? " is-invalid" : "";
        inputClasses += " is-invalid";
    }

    const removeNewOption = (option: any) => {
        //TODO make sure option is removed
        options = options.filter((f: any) => f !== option);

        let currentLocalOptions = [...localOptions];
        currentLocalOptions = currentLocalOptions.filter((f: any) => f !== option);
        setLocalOptions(currentLocalOptions);

        let currentNewOptions = [...newOptions];
        currentNewOptions = currentNewOptions.filter((f: any) => f !== option);
        setNewOptions(currentNewOptions);

        delegateChange(option);
    };

    const delegateChange = (option: any, checked = false) => {
        let eventObject = {
            target: {
                name: name,
                value: option,
                type: "checkbox"
            }
        };
        if (checked) {
            // @ts-ignore
            eventObject.target["checked"] = true;
        }
        handler && handler(eventObject);
    };

    let divStyles: any = {
        width: '100%',
        display: 'flex',
        marginBottom: '0.5rem',
        cursor: 'pointer',
        alignItems: 'center'
    };
    if (disabled) {
        divStyles["cursor"] = 'unset';
    }

    if (typeof style === "undefined") {
        style = {marginBottom: '1rem'};
    } else {
        style = {
            ...style,
            marginBottom: '1rem'
        }
    }

    if (isSummary) {
        style = {
            ...style,
            flexDirection: "row"
        }
    }

    if (asSwitch) {
        style = {
            ...style,
            padding: 0
        }
    }

    return (
        <div style={style} className="form-column">
            <label
                className={!isValid ? "is-invalid" : ""}
                htmlFor="companyName"
                style={isSummary ? {width: '50%'} : {}}
            >
                {disabled ? <b>{title}:</b> : <span>{title}{{isRequired} ? ' *' : ''}</span>}
            </label>
            <div className="form-column" style={isSummary ? {width: '50%'} : {}}>
                {localOptions.map((option: string, idx: number) =>
                    <>
                        {asSwitch &&
                        <div className="custom-control custom-switch" style={{marginBottom: '0.7rem'}}>
                            <input
                                checked={values[idx] === true}
                                name={name[idx]}
                                onChange={handler}
                                value={values[idx]}
                                type="checkbox"
                                className={inputClasses}
                                id={name[idx]}
                            />
                            <label className="custom-control-label" htmlFor={name[idx]}>
                                <span style={{marginLeft: '1rem'}}>{option}</span>
                            </label>
                        </div>
                        }
                        {!asSwitch &&
                        <div style={divStyles}>
                            <div style={renderAddability ? {maxWidth: '83%', width: '83%'} : {}}>
                                <div>
                                    <input
                                        style={disabled ? {cursor: 'unset'} : {}}
                                        checked={checked || (values && values.includes(option))}
                                        disabled={disabled}
                                        className="checkbox"
                                        onChange={handler}
                                        type="checkbox"
                                        value={option}
                                        name={name}
                                    />
                                    <span className={spanClasses} />
                                </div>
                                <div
                                    style={{marginLeft: '2rem'}}
                                    onClick={() => delegateChange(option)}
                                >
                                    <span style={{paddingBottom: '1px'}}>
                                        {option}
                                    </span>
                                </div>
                            </div>
                            {newOptions.includes(option) &&
                            <div style={{width: '17%'}}>
                                <button
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: 'none',
                                        border: 'none',
                                        padding: 0
                                    }}
                                    onClick={() => removeNewOption(option)}
                                >
                                    <IconRemove width={20} height={20} />
                                </button>
                            </div>
                            }
                        </div>
                        }
                    </>
                )}
            </div>
            {renderAddability &&
            <div className="form-group" style={{display: 'flex', flexDirection: 'row', marginTop: '0.5rem'}}>
                <input
                    style={{maxWidth: '15rem'}}
                    type="text" ref={inputRef}
                    className={"form-control" + (isInvalid ? " is-invalid" : "")}
                    id="optional"
                    name="optional" />
                <button
                    onClick={addNewOption}
                    style={{marginLeft: '1rem'}}
                    className="btn btn-primary"
                >
                    hinzufügen
                </button>
            </div>
            }
        </div>
    );
}

export default CheckboxGroup;