import React from "react";
import { ReactComponent as IconNext } from '../../styles/icons/chevron-right.svg';
import { ReactComponent as IconPrevious } from '../../styles/icons/chevron-left.svg';
import { ReactComponent as IconFirst } from '../../styles/icons/chevron-double-left.svg';
import { ReactComponent as IconLast } from '../../styles/icons/chevron-double-right.svg';
import { ReactComponent as ExportIcon } from '../../styles/icons/export.svg';

interface PaginationProps {
    rowsPerPage: number;
    rowCount: number;
    currentPage: number;
    direction?: string;
    paginationRowsPerPageOptions?: number[];
    paginationIconLastPage?: React.ReactNode;
    paginationIconFirstPage?: React.ReactNode;
    paginationIconNext?: React.ReactNode;
    paginationIconPrevious?: React.ReactNode;
    paginationComponentOptions?: any;
    onChangePage: (page: number) => void;
    onChangeRowsPerPage: (numRows: number, currentPage: number) => void;
    onExportAction: any,
}

export default function DashboardPagination(
    {
        rowsPerPage,
        rowCount,
        currentPage,
        paginationRowsPerPageOptions = [10, 15, 20, 25, 30],
        onChangeRowsPerPage,
        onChangePage,
        onExportAction,
    }: PaginationProps
) {
    const selectOptions = paginationRowsPerPageOptions.map((num: number) => (
        <option key={num} value={num}>
            {num}
        </option>
    ));

    const getNumberOfPages = (rowCount: number, rowsPerPage: number) => {
        return Math.ceil(rowCount / rowsPerPage);
    };

    const handlePrevious = React.useCallback(() => {
            if (currentPage > 1) {
                onChangePage(currentPage - 1)
            }
        },
        [currentPage, onChangePage]
    );

    const handleNext = React.useCallback(() => onChangePage(currentPage + 1), [currentPage, onChangePage]);
    const handleFirst = React.useCallback(() => onChangePage(1), [onChangePage]);
    const handleLast = React.useCallback(
        () => onChangePage(getNumberOfPages(rowCount, rowsPerPage)),
        [onChangePage, rowCount, rowsPerPage],
    );

    const options = {
        selectAllRowsItem: false,
        selectAllRowsItemText: "Alle auswählen",
        rowsPerPageText: "von"
    };

    if (options.selectAllRowsItem) {
        selectOptions.push(
            <option key={-1} value={rowCount}>
                {options.selectAllRowsItemText}
            </option>,
        );
    }

    const iconDimensions = {
        width: 20,
        height: 20
    };

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === getNumberOfPages(rowCount, rowsPerPage);

    return <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: '25px 30px 25px 30px',
        background: '#F7F7F7',
        color: '#909090',
        alignItems: 'center'
    }}>
        <div>{rowCount} Einträge</div>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '20px',
            color: '#000'
        }}>
            <div
                className={"ml-1 mr-1"}
                style={{cursor: (isFirstPage ? 'not-allowed' : 'pointer'), color: (isFirstPage ? '#909090' : '#000')}}
                onClick={handleFirst}
            >
                <IconFirst
                    width={iconDimensions["width"]}
                    height={iconDimensions["height"]}
                />
            </div>
            <div
                className={"ml-1 mr-1"}
                style={{cursor: (isFirstPage ? 'not-allowed' : 'pointer'), color: (isFirstPage ? '#909090' : '#000')}}
                onClick={handlePrevious}
            >
                <IconPrevious
                    width={iconDimensions["width"]}
                    height={iconDimensions["height"]}
                />
            </div>
            <div className={"ml-1 mr-1"}
                 style={{paddingTop: '3px'}}>{currentPage} von {Math.ceil(rowCount / rowsPerPage)}</div>
            <div
                className={"ml-1 mr-1"}
                style={{cursor: (isLastPage ? 'not-allowed' : 'pointer'), color: (isLastPage ? '#909090' : '#000')}}
                onClick={handleNext}
            >
                <IconNext
                    width={iconDimensions["width"]}
                    height={iconDimensions["height"]}
                />
            </div>
            <div
                className={"ml-1 mr-1"}
                style={{cursor: (isLastPage ? 'not-allowed' : 'pointer'), color: (isLastPage ? '#909090' : '#000')}}
                onClick={handleLast}
            >
                <IconLast
                    width={iconDimensions["width"]}
                    height={iconDimensions["height"]}
                />
            </div>
        </div>
        <div style={{
            display: 'flex'
        }}>
            <button
                className="btn btn-primary dropdown-toggle"
                type="button"
                id="dropdownMenuButtonTwo"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
                onClick={onExportAction}
            >
                <ExportIcon/>
                &nbsp;
                Export
            </button>
        </div>
    </div>;
}
