import React from 'react';
import { Route, Switch } from "react-router-dom";
import Login from "../../pages/login";
import PositionsOverview from "../../pages/onejob/PositionsOverview";
import Index from "../../pages";
import { useSelector } from "react-redux";
import { State } from "../../redux/reducer";
import SideNavigation from "../SideNavigation";
import Navigation from "../Navigation";
import history from "../../redux/history";
import { userRoles } from "../../redux/constants";

const RouterComponent = () => {
    const user = useSelector((state: State) => state.auth.user);
    const userRole = useSelector((state: State) => state.auth.userRole);
    const {pathname} = history.location;
    let pageLayout = "_default";
    let renderSidebar = false;
    if (userRole === userRoles.ADMIN && pathname !== "/") {
        pageLayout = "_columns";
        renderSidebar = true;
    }

    let html = [];
    if (user === null) {
        html.push(<Login />);
    } else {
        if (userRole === userRoles.EMPLOYEE) {
            html.push(<Route path="/" component={Index} />);
        }
        if (userRole === userRoles.ADMIN) {
            html.push(
                <Switch>
                    <Route path="/positions" component={PositionsOverview} />
                    <Route path="/" component={Index} />
                </Switch>
            );
        }
    }

    return (
        <>
            {user !== null &&
                <div className={`header ${pageLayout}-navbar`}>
                    <Navigation page={pathname} />
                </div>
            }
            <div className={`__page-content ${pageLayout}`}>
                {renderSidebar && user && userRole === userRoles.ADMIN &&
                <SideNavigation />
                }
                <div className={"__main-content"}>
                    {html}
                </div>
            </div>
        </>
    )
};

export default RouterComponent;