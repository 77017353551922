import React, { FC } from "react";

interface Props {
    title: string,
    value: any
}

const DisplayComponent: FC<Props> = ({title, value}) => {
    if (typeof value === "object") {
        let tmp = "";
        value.forEach((v: any, idx: number) => {
            tmp += v + (value.length-1 === idx ? "" : ", ");
        });
        value = tmp;
    }
    return (
        <div className="form-row" style={{marginBottom: '1rem'}}>
            <div style={{width: '50%'}}>
                <span><b>{title}:</b></span>
            </div>
            <div style={{width: '50%'}}>
                <span>{value}</span>
            </div>
        </div>
    );
}

export default DisplayComponent;