import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import { postLogin, requestApiEndpoint } from '../services/ApiService';
import history from './history';
import jwt_decode from "jwt-decode";
import { userRoles } from "./constants";

export interface UserLogin {
    type: 'LOGIN_FETCHING'
    isFetching: boolean
}

export interface SetUserCredentials {
    type: 'USER_CREDENTIALS_SET',
    access_token: string | null,
    refresh_token: string | null,
    user: any | null
}

export interface SetUserRole {
    type: 'USER_ROLE_SET',
    userRole: string | null
}

export const fetchUserLogin = (isFetching: boolean): UserLogin => {
    return {
        type: 'LOGIN_FETCHING',
        isFetching
    }
};

export const setNavigation = (menu: any) => {
    return {
        type: 'SET_NAVIGATION',
        menu: menu
    }
};

export const setUserCredentials = (access_token: string | null, refresh_token: string | null, user: any | null): SetUserCredentials => {
    return {
        type: 'USER_CREDENTIALS_SET',
        access_token,
        refresh_token,
        user
    }
};

export const setUserRole = (userRole: string | null): SetUserRole => {
    return {
        type: 'USER_ROLE_SET',
        userRole
    }
};

export const loginError = (error: string | null) => {
    return {
        type: 'LOGIN_ERROR',
        error
    }
};

export const setCompanyFilter = (value: string) => {
    return {
        type: 'SET_COMPANY_FILTER',
        companyFilter: value
    }
};

export const setOccupationFilter = (value: string) => {
    return {
        type: 'SET_OCCUPATION_FILTER',
        occupationFilter: value
    }
};

export const updateStatusForUser = (status: string, personalNumber: string, year: number | null): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    const requestBody = new FormData();
    requestBody.append('personal_numbers', personalNumber);
    requestBody.append('participation_status', status);

    if (year !== null) {
        requestBody.append('year', year.toString());
    }

    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        await requestApiEndpoint(requestBody, '/management/employee/set-participation')
            .then(data => {
                console.log('Status upgedated.')
            })
            .catch(err => {
                console.log('Status konnte nicht upgedated werden.')
            })
    }
};

export const loginUser = (email: string, password: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    const requestBody = new FormData();
    requestBody.append('username', email);
    requestBody.append('password', password);
    let accessToken = "";
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        await postLogin(email, password)
        .then(data => {
            accessToken = data.access_token;
            dispatch(setUserCredentials(accessToken, data.refresh_token, jwt_decode(data.access_token)));
        }).then(result => {
            getUserRole(email, accessToken, dispatch);
        })
        .catch(err => {
            dispatch(loginError('Invalid login.'));
        })
    }
};

const getUserRole = (userName: any, accessToken: any, dispatch: any) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/get`,
        {
            method: "POST",
            body: JSON.stringify({userName: userName}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        }
    )
    .then(response => response.json())
    .then(json => dispatch(setUserRole(json.userRole)))
    .then(result => {
        loginError(null);
        if (result.userRole === userRoles.ADMIN) {
            history.push("/positions");
        }
        if (result.userRole === userRoles.EMPLOYEE) {
            history.push("/")
        }
    })
    .catch((error) => {
        dispatch(loginError("Invalid user role!"));
        console.log("Get User role error:", error);
    });
};

export const logoutUser = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        dispatch(setUserCredentials(null, null, null));
    }
};
