import React, { FC, useState, useEffect } from "react";
import history from "../../redux/history";
import { useSelector } from "react-redux";
import { State } from "../../redux/reducer";
import Select from "react-select";

interface Props {
    name: string,
    options: any[],
    title?: string,
    handler?: React.EventHandler<any>,
    isRequired?: boolean,
    value?: string | any[],
    placeholder?: string,
    style?: any,
    isValid?: any,
    noDefaultOption?: any,
    disabled?: boolean,
    tabIndex?: number,
    isMulti?: boolean
}

const DropdownComponent: FC<Props> = (
    {
        name,
        options,
        title,
        handler,
        isRequired,
        value,
        placeholder,
        style,
        isValid,
        noDefaultOption,
        disabled,
        tabIndex,
        isMulti
    }
) => {
    if (typeof isValid === "undefined") {
        isValid = true;
    }

    let classes = "";
    if (title) {
        classes += "form-column";
    }
    let selectClasses = "__select";
    if (!isValid) {
        selectClasses += " is-invalid";
    }

    const styles = {
        container: (styles: any) => ({...styles, minWidth: '150px', color: 'grey'}),
        menuPortal: (base: any) => ({...base, zIndex: 9999}),
        option: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
            if (data.value === "") {
                return {
                    ...styles,
                    backgroundColor: isFocused || isSelected ? "#FA350F" : "null",
                    color: 'grey'
                }
            }
            return {
                ...styles,
                backgroundColor: isFocused || isSelected ? "#FA350F" : "null",
                color: isSelected ? "#FFFFFF" : "null"
            };
        },
        singleValue: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
            return {
                ...styles,
                color: data.value === "" ? 'grey' : styles.color
            }
        }
    };

    const allOptions: any[] = [];
    if (!noDefaultOption) {
        allOptions.push({label: (placeholder ? placeholder : title + " wählen"), value: ''});
    }
    options.forEach((option: string) => {
        if (option === "---") {
            allOptions.push({
                label: "---",
                value: ""
            });
        } else {
            allOptions.push({
                label: option,
                value: option
            });
        }
    });

    if (isMulti && value && value.length) {
        let tmp: any[] = [];
        console.log(value);
        //@ts-ignore
        value.forEach((element: any) => {
            console.log(element);
            tmp.push({value: element, label: element});
        });
        value = tmp;
        console.log(value);
    }

    let [valueState, setValueState] = useState(isMulti ? value : {value: value, label: value});

    const [placeholderState, setPlaceholderState] = useState(
        placeholder ? placeholder : title + " wählen"
    );

    const hidePlaceholderOnFocus = isMulti ? undefined : (e: any) => {
        setPlaceholderState("");
        setValueState({value: "", label: ""});
    };

    const hideDisplayedValuesOnBlur = isMulti ? undefined : () => {
        // @ts-ignore
        setPlaceholderState(placeholder ? placeholder : title + " wählen");
        // @ts-ignore
        if (valueState && valueState.value === "") {
            setValueState({value: value, label: value});
        }
    };

    let selectRef: any = React.createRef();

    const delegateChange = (e: any) => {
        selectRef.blur();
        setValueState(e);
        handler && handler({
            target: {
                name: name,
                value: (isMulti ? e[e.length - 1].value : e.value),
                type: (isMulti ? "checkbox" : "dropdown")
            }
        });
    }

    if (!style) {
        if (!noDefaultOption) {
            style = {marginBottom: '1rem'};
        } else {
            style = {};
        }
    } else {
        if (!noDefaultOption && style.marginBottom !== 0) {
            style = {
                ...style,
                marginBottom: '1rem'
            };
        }
    }

    return (
        <div id={name} style={style} className={classes}>
            {title &&
            <label
                className={!isValid ? "is-invalid" : ""}
                htmlFor={name}>{title}
                {isRequired ? ' *' : ''}
            </label>
            }
            <Select
                isMulti={isMulti}
                tabIndex={tabIndex}
                isDisabled={disabled}
                ref={r => selectRef = r}
                onBlur={hideDisplayedValuesOnBlur}
                onFocus={hidePlaceholderOnFocus}
                name={name}
                placeholder={placeholderState}
                styles={styles}
                onChange={delegateChange}
                options={allOptions}
                className={selectClasses}
                classNamePrefix={"__select-inner"}
                menuPortalTarget={document.body}
                value={isMulti ? valueState : (value && value !== "" && {value: value, label: value})}
            />
        </div>
    );
}

export default DropdownComponent;
