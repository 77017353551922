import React, { FC } from "react";
import { careerEntries } from "../../dummyData/careerEntries";
import { careerLevels } from "../../dummyData/careerLevels";
import { workAreas } from "../../dummyData/workAreas";
import DropdownComponent from "./DropdownComponent";
import CheckboxGroup from "./CheckboxGroup";
import { domains } from "../../dummyData/domains";
import { requirements } from "../../dummyData/requirements";
import { contractTypes } from "../../dummyData/contractTypes";
import { salaries } from "../../dummyData/salaries";
import { bonuses } from "../../dummyData/bonuses";
import { benefits } from "../../dummyData/benefits";
import InputComponent from "./InputComponent";
import StepButton from "../ui/StepButton";
import EditorComponent from "../ui/EditorComponent";
import DownloadComponent from "../ui/DownloadComponent";
import { occupations, occupationTypes } from "../../dummyData/occupations";
import { amounts } from "../../dummyData/amounts";
import moment from "moment";
import { companies } from "../../dummyData/companies";


interface Props {
    handler: React.EventHandler<any>,
    globalState: any,
    validation: any,
    initialOptions: any
}

const PositionForm: FC<Props> = (
    {
        initialOptions,
        handler,
        globalState,
        validation
    }
) => {
    let occupationNames: any[] = [];
    occupations.forEach((object: any) => {
        if (globalState.position !== "" && globalState.position === object.group) {
            occupationNames.push(object.name);
        }
    });
    occupationNames.sort((a, b) => a.localeCompare(b));

    const getPathOfOccupation = () => {
        let path = "";
        occupations.forEach((object: any) => {
            if (object.name === globalState.occupationName) {
                path = object.path;
            }
        });
        return path;
    };

    const handlePositionChange = (e: any) => {
        handler(e);

        if (globalState.position !== e.target.value) {
            handler({
                target: {
                    name: "occupationName",
                    value: "",
                    type: "dropdown"
                }
            })
        }
    };

    return (
        <div className={"__register-wrapper"}>
            <div className={"row"}>
                <div className={"col-12 col-md-6 col-lg-7"}>
                    <div className={"__info-wrapper"}>
                        <div className={"__header"}>
                            <h3 className={"__headline"}>
                                Angaben zur Stelle
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-header-title">
                        Stellenbezogene Daten
                    </h4>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-column form-container">
                            <InputComponent
                                tabIndex={1}
                                title="Standort"
                                name="location"
                                isValid={!validation.location.error}
                                isRequired={validation.location.required}
                                handler={handler}
                                value={globalState.location}
                            />
                            <DropdownComponent
                                tabIndex={2}
                                options={amounts}
                                title="Anzahl"
                                name="number"
                                isValid={!validation.number.error}
                                isRequired={validation.number.required}
                                handler={handler}
                                value={globalState.number}
                            />
                            <DropdownComponent
                                tabIndex={3}
                                options={Object.values(occupationTypes)}
                                title="Berufsgruppe"
                                name="position"
                                isValid={!validation.position.error}
                                isRequired={validation.position.required}
                                handler={handlePositionChange}
                                value={globalState.position}
                            />
                            <DropdownComponent
                                tabIndex={4}
                                options={occupationNames}
                                title="Berufsbezeichnung"
                                name="occupationName"
                                isValid={globalState.position !== "" ? !validation.occupationName.error : true}
                                isRequired={validation.occupationName.required}
                                handler={handler}
                                value={globalState.occupationName}
                                disabled={globalState.position === ""}
                            />
                            <DownloadComponent pathToFile={
                                globalState.occupationName !== "" ?
                                    getPathOfOccupation() : ""
                            }
                            />
                            <EditorComponent
                                title="Ergänzung weiterer Anmerkungen (optional)"
                                handler={handler}
                                style={{color: '#909090'}}
                                value={globalState.comment}
                            />
                        </div>
                        <div className="form-column form-container" style={{borderLeft: '1px solid rgba(0,0,0,0.09)'}}>
                            <DropdownComponent
                                tabIndex={6}
                                options={careerLevels}
                                title="Karrierestufe"
                                name="careerLevel"
                                isValid={!validation.careerLevel.error}
                                isRequired={validation.careerLevel.required}
                                handler={handler}
                                value={globalState.careerLevel}
                            />
                            <CheckboxGroup
                                values={globalState.domains}
                                options={domains}
                                name="domains"
                                isValid={!validation.domains.error}
                                isRequired={validation.domains.required}
                                title="Einsatzgebiet"
                                handler={handler}
                            />
                            <DropdownComponent
                                tabIndex={7}
                                options={workAreas}
                                title="Arbeitsbereich"
                                name="workArea"
                                isValid={!validation.workArea.error}
                                isRequired={validation.workArea.required}
                                handler={handler}
                                value={globalState.workArea}
                                isMulti
                            />
                            <CheckboxGroup
                                isValid={!validation.requirements.error}
                                values={globalState.requirements}
                                initialOptions={initialOptions.requirements}
                                options={requirements}
                                name="requirements"
                                renderAddability
                                isRequired={validation.requirements.required}
                                title="Anforderungen"
                                handler={handler}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-header-title">
                        Vertragsbezogene Daten
                    </h4>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-column form-container">
                            <InputComponent
                                tabIndex={8}
                                title="Beginn"
                                name="startDate"
                                type="date"
                                value={globalState.startDate}
                                handler={handler}
                                isRequired={validation.startDate.required}
                                isValid={!validation.startDate.error}
                                minDate={moment(new Date()).format("YYYY-MM-DD")}
                            />
                            <CheckboxGroup
                                isValid={!validation.contractTypes.error}
                                values={globalState.contractTypes}
                                options={contractTypes}
                                name="contractTypes"
                                isRequired={validation.contractTypes.required}
                                title="Vertragsart"
                                handler={handler}
                            />
                            <CheckboxGroup
                                isValid={!validation.salaries.error}
                                values={globalState.salaries}
                                options={salaries}
                                name="salaries"
                                isRequired={validation.salaries.required}
                                title="Gehalt"
                                handler={handler}
                            />
                            <CheckboxGroup
                                isValid={!validation.bonuses.error}
                                values={globalState.bonuses}
                                options={bonuses}
                                name="bonuses"
                                isRequired={validation.bonuses.required}
                                title="Boni-Zahlungen"
                                handler={handler}
                            />
                        </div>
                        <div className="form-column form-container" style={{borderLeft: '1px solid rgba(0,0,0,0.09)'}}>
                            <InputComponent
                                tabIndex={9}
                                title="Urlaubsanspruch in Tagen"
                                name="vacationClaims"
                                type="number"
                                value={globalState.vacationClaims}
                                handler={handler}
                                isRequired={validation.vacationClaims.required}
                                isValid={!validation.vacationClaims.error}
                            />
                            <CheckboxGroup
                                isValid={!validation.benefits.error}
                                values={globalState.benefits}
                                initialOptions={initialOptions.benefits}
                                options={benefits}
                                name="benefits"
                                renderAddability
                                isRequired={validation.benefits.required}
                                title="Benefits"
                                handler={handler}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <StepButton alignment="Left" type="back" handler={handler} step={globalState.step} />
                <StepButton tabIndex={10} alignment="Right" type="next" handler={handler} step={globalState.step} />
            </div>
        </div>
    );
}

export default PositionForm;
