import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { CSVHeaders, UserDataRow } from "../../types";
import DashboardFilter from "../../components/DashboardFilter";
import DashboardPagination from "../../components/Basic/DashboardPagination";
import { STATUS_STATES } from "./Columns";
import OccupationOverview from "./OccupationOverview";
import { ReactComponent as IconNext } from "../../styles/icons/chevron-right.svg";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../redux/reducer";
import history from "../../redux/history";
import { loginError, setCompanyFilter, setOccupationFilter } from "../../redux/actions";
import DropdownComponent from "../../components/Form/DropdownComponent";
import moment from "moment";

export default function PositionsOverview(): JSX.Element {
    const authToken = useSelector((state: State) => state.auth.accessToken);
    const updateStatus = (value: any, id: any) => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/position/update`,
            {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    status: value,
                    id: id
                }),
                method: 'POST'
            }
        ).then(response => response.json()
        ).catch((error: any) => {
            console.log(error);
        });
    };

    const [triggerRedraw, setTriggerRedraw] = useState(false);

    const columns: any[] = [
        {
            id: 1,
            name: 'Unternehmen',
            selector: 'companyNumber',
            sortable: true,
            omit: false,
        },
        {
            id: 2,
            name: 'Standort',
            selector: 'location',
            sortable: true,
            omit: false,
        },
        {
            id: 3,
            name: 'Berufsbezeichnung',
            selector: 'occupationName',
            sortable: true,
            omit: false,
        },
        {
            id: 4,
            name: 'Eingegangen',
            selector: 'receivedDate',
            sortable: true,
            omit: false,
        },
        {
            id: 5,
            name: 'Status',
            selector: 'status',
            sortable: false,
            omit: false,
            cell: (row: any) => {
                return <DropdownComponent
                    options={Object.values(STATUS_STATES)}
                    name="status"
                    value={row.status}
                    noDefaultOption
                    handler={(e) => {
                        row.status = e.target.value;
                        updateStatus(e.target.value, row.id);
                        let targetIndex = 0;
                        filteredData.forEach((r: any, idx: number) => {
                           if (r.id === row.id) {
                               targetIndex = idx;
                           }
                        });
                        setTriggerRedraw(!triggerRedraw);
                    }}
                />;
            }
        }
    ];

    let showDatatable = true;
    let useDashboardHeader = false;
    const dispatch = useDispatch();

    const CsvPositionMapping: CSVHeaders = {
        "companyName": "Unternehmen",
        "location": "Standort",
        "occupationName": "Berufsbezeichnung",
        "receivedDate": "Eingegangen",
        "status": "Status",
        "vacationClaims": "Urlaubsanspruch in Tagen",
        "careerEntry": "Berufseinstieg",
        "number": "Anzahl",
        "careerLevel": "Karrierestufe",
        "domains": "Einsatzgebiet",
        "position": "Position",
        "workArea": "Arbeitsbereich",
        "requirements": "Anforderungen",
        "startDate": "Beginn",
        "contractTypes": "Vertragsart",
        "salaries": "Gehalt",
        "bonuses": "Boni-Zahlungen",
        "benefits": "Benefits",
        "comment": "Kommentar",
        "companyNumber": "Betriebsnummer ",
        "firstName": "Vorname",
        "lastName": "Nachname",
        "email": "Mailadresse",
        "phoneNumber": "Telefon",
        "printIsActive": "Channel Print",
        "printFrom": "Print aktiv ab",
        "printTo": "Print aktiv bis",
        "socialMediaIsActive": "Channel Social Media",
        "socialMediaFrom": "Social Media aktiv ab",
        "socialMediaTo": "Social Media aktiv bis",
        "careerWebsiteIsActive": "Channel Karriere",
        "careerWebsiteFrom": "Karriere aktiv ab",
        "careerWebsiteTo": "Karriere aktiv bis"
    };

    const companyFilter = useSelector((state: State) => state.app.companyFilter);
    const occupationFilter = useSelector((state: State) => state.app.occupationFilter);
    const [companies, setCompanies] = useState<string[]>([]);
    const [occupationNames, setOccupationNames] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData]: any = useState([]);
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [currentId, setCurrentId] = useState(0);
    const [updatingDatatable, setUpdatingDatable] = React.useState(false);
    const [filter, setFilter] = useState({
        text: '',
        companyName: companyFilter,
        occupationName: occupationFilter,
    } as any);

    const filterLabels = {
        companies: {
            key: 'companyName',
            values: companies,
            placeholder: "Unternehmen"
        },
        occupations: {
            key: 'occupationName',
            values: occupationNames,
            placeholder: "Berufsbezeichnung"
        }
    };

    const [validation, setValidation] = useState({
        channels: {
            required: false,
            error: false
        },
        printFrom: {
            required: false,
            error: false
        },
        printTo: {
            required: false,
            error: false
        },
        socialMediaFrom: {
            required: false,
            error: false
        },
        socialMediaTo: {
            required: false,
            error: false
        },
        careerWebsiteFrom: {
            required: false,
            error: false
        },
        careerWebsiteTo: {
            required: false,
            error: false
        }
    });

    const prepareFilterDropdownValues = () => {
        const companyList: string[] = [];
        data && data.forEach((obj: any) => {
            if (!companyList.includes(obj.companyName)) {
                companyList.push(obj.companyName);
            }
        });
        setCompanies(companyList);

        const occupationList: string[] = [];
        data && data.forEach((obj: any) => {
            if (!occupationList.includes(obj.occupationName)) {
                occupationList.push(obj.occupationName);
            }
        });
        setOccupationNames(occupationList);
    };

    const getAllPositions = async () => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/position/getAll`,
            {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            }
        ).then(response => response.status === 200 ? response : Promise.reject(response.statusText)
        ).then(response => response.json()
        ).then((json: any) => {
                if (json.data) {
                    Object.values(json.data).forEach((row: any) => {
                        let tmp = [];
                        row.printIsActive && tmp.push("printIsActive");
                        row.socialMediaIsActive && tmp.push("socialMediaIsActive");
                        row.careerWebsiteIsActive && tmp.push("careerWebsiteIsActive");
                        row.printFrom === null && (row["printFrom"] = "");
                        row.printTo === null && (row["printTo"] = "");
                        row.socialMediaFrom === null && (row["socialMediaFrom"] = "");
                        row.socialMediaTo === null && (row["socialMediaTo"] = "");
                        row.careerWebsiteFrom === null && (row["careerWebsiteFrom"] = "");
                        row.careerWebsiteTo === null && (row["careerWebsiteTo"] = "");
                        row["channels"] = tmp;
                    });
                    let result = json.data;
                    if (companyFilter !== "" || occupationFilter !== "") {
                        result = filterData(json.data);
                    }

                    setFilteredData(result);
                    setData(json.data);
                }
            }
        ).catch((error: any) => {
            console.log("Error:", error);
            dispatch(loginError('Invalid login.'));
        });
    };

    useEffect(() => {
        getAllPositions()
    }, []);

    useEffect(prepareFilterDropdownValues, [data]);

    const handleRowClicked = (row: any) => {
        let id: number = 0;
        Object.values(filteredData).forEach((object: any, idx) => {
            if (object.id === row.id) {
                id = idx;
            }
        });
        setCurrentId(id + 1);

        const validationObject = {...validation};
        validationObject.channels.required = (filteredData[id].status === STATUS_STATES.public);
        if (filteredData[id].printIsActive) {
            validationObject.printFrom.required = true;
        }
        if (filteredData[id].socialMediaIsActive) {
            validationObject.socialMediaFrom.required = true;
        }
        if (filteredData[id].careerWebsiteIsActive) {
            validationObject.careerWebsiteFrom.required = true;
        }
        setValidation(validationObject);
    };

    const downloadAsCSV = (data: any, filename: string, headers: CSVHeaders) => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data, headers);
        if (csv) {
            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`;
            }
            link.setAttribute('href', encodeURI(csv));
            link.setAttribute('download', filename);
            link.click();
            setClearSelectedRows(!clearSelectedRows);
        }
    };

    function convertArrayOfObjectsToCSV(array: UserDataRow[], headers: CSVHeaders) {
        let result: any;

        const columnDelimiter = '","';
        const lineDelimiter = '"\n';
        const keys = Object.keys(headers);

        const headerList = Object.keys(headers);
        const headerLabels = Object.values(headers);
        if (headerList.indexOf('name') > 0) {
            headerList.splice(headerList.indexOf('name'), 1);
            headerLabels.splice(headerLabels.indexOf('Name'), 1);
        }

        result = '"';
        result += headerLabels.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach((item: any) => {
            let ctr = 0;
            result += '"';
            keys.forEach((key) => {
                if (typeof item[key] === "boolean") {
                    item[key] = item[key] === true ? "ja" : "nein";
                }
                if (headerList.includes(key)) {
                    if (ctr > 0) {
                        result += columnDelimiter;
                    }
                    result += item[key];
                    ctr++;
                }
            });
            result += lineDelimiter;
        });

        return result;
    }

    const filterData = (json = null) => {
        let localData = [...data];
        if (json !== null) {
            localData = json;
        }

        if (filteredData === null) {
            return;
        }

        let hasFilter: any[] = [];
        Object.values(filter).forEach((term) => {
            if (term !== "") {
                hasFilter.push(term);
            }
        });

        if (!hasFilter.length) {
            setFilteredData(data);
            return
        }

        let tmp = localData;
        if (localData.length > 0) {
            tmp = localData.filter((row: any) => {
                let matchFound = true;
                let textFound = false;
                Object.keys(row).map((key) => {
                    if (typeof row[key] === 'object' || typeof row[key] === 'boolean') {
                        return
                    }
                    let value = row[key];
                    if (typeof value === 'number') {
                        value = value.toString();
                    }
                    if (filter[key] && filter[key] !== "") {
                        matchFound = matchFound && row[key] === filter[key];
                    }
                    if (filter.text !== "") {
                        if (value.includes(filter.text)) {
                            textFound = true;
                        }
                    } else {
                        textFound = true;
                    }
                });

                return matchFound && textFound;
            });
            setFilteredData(tmp);
            if (json !== null) {
                return tmp;
            }
        }
    };
    // @ts-ignore
    useEffect(filterData, [filter]);

    const paginationSettings = {
        rowsPerPageText: 'Zeilen pro Seite:'
    };

    const onExportAction = async () => {
        const fileName = moment(new Date()).format("YY-MM-DD_HHmm") + "_onejob.csv";
        downloadAsCSV(filteredData, fileName, CsvPositionMapping);
    };

    const handleFilterChange = (e: any) => {
        if (e.target.name === "companyName") {
            dispatch(setCompanyFilter(e.target.value));
        } else if (e.target.name === "occupationName") {
            dispatch(setOccupationFilter(e.target.value));
        }
        setFilter({...filter, [e.target.name]: e.target.value});
    };

    useEffect(() => {
        setFilter({text: '', companyName: companyFilter, occupationName: occupationFilter});
    }, []);

    let DashboardFilterWrapper = <div style={{width: '100%'}}>
        <div style={{display: 'flex', width: '100%', justifyContent: 'start', padding: '25px 10px'}}>
            <div className={'dropdown-filter'} style={{alignItems: 'center', display: 'flex', flexWrap: 'wrap'}}>
                <DashboardFilter
                    onFilter={(e: any) => {
                        setFilter({...filter, text: e.target.value});
                    }}
                    filterText={filter.text}
                />
                <div className="form-row">
                    {Object.values(filterLabels).map((filterObject: any) => {
                        return <DropdownComponent
                            value={filterObject.key === "companyName" ? companyFilter : occupationFilter}
                            placeholder={filterObject.placeholder}
                            key={filterObject.key}
                            name={filterObject.key}
                            options={filterObject.values}
                            handler={handleFilterChange}
                            style={{maxWidth: '230px', minWidth: "150px", width: '190px', marginLeft: '1.5rem', marginBottom: 0}}
                        />
                    })}
                </div>
            </div>
        </div>
    </div>;

    const handleChange = (e: any) => {
        if (e.target.type === "checkbox") {
            // @ts-ignore
            const currentData = {...filteredData[currentId - 1]};
            currentData[e.target.name] = e.target.checked;

            let currentValidation = {...validation};
            if (e.target.name === "printIsActive") {
                if (e.target.checked) {
                    currentValidation.printFrom.required = true;
                    currentData.channels.push(e.target.name);
                } else {
                    currentValidation.printFrom.required = false;
                    currentData.channels = currentData.channels.filter((f: any) => f !== e.target.name);
                    currentData.printFrom = "";
                    currentData.printTo = "";
                }
            }
            if (e.target.name === "socialMediaIsActive") {
                if (e.target.checked) {
                    currentValidation.socialMediaFrom.required = true;
                    currentData.channels.push(e.target.name);
                } else {
                    currentValidation.socialMediaFrom.required = false;
                    currentData.channels = currentData.channels.filter((f: any) => f !== e.target.name);
                    currentData.socialMediaFrom = "";
                    currentData.socialMediaTo = "";
                }
            }
            if (e.target.name === "careerWebsiteIsActive") {
                if (e.target.checked) {
                    currentValidation.careerWebsiteFrom.required = true;
                    currentData.channels.push(e.target.name);
                } else {
                    currentValidation.careerWebsiteFrom.required = false;
                    currentData.channels = currentData.channels.filter((f: any) => f !== e.target.name);
                    currentData.careerWebsiteFrom = "";
                    currentData.careerWebsiteTo = "";
                }
            }
            currentValidation.channels.error = false;
            setValidation(currentValidation);

            setFilteredData((prevState: any) => ({
                ...prevState,
                [currentId - 1]: currentData
            }));
        } else {
            const currentData = {...filteredData[currentId - 1]};
            currentData[e.target.name] = e.target.value;
            setFilteredData((prevState: any) => ({
                ...prevState,
                [currentId - 1]: currentData
            }));

            const validationObject = {...validation};
            validationObject.channels.required = (currentData.status === STATUS_STATES.public);
            setValidation(validationObject);
        }
    };

    const isValid = () => {
        let result = true;
        let validationObject = {...validation};
        Object.keys(validation).forEach((key: any) => {
            // @ts-ignore
            if (validation[key].required) {
                // @ts-ignore
                const value = filteredData[currentId - 1][key];
                let valid = true;
                if (typeof value === 'string') {
                    valid = (value !== '');
                } else if (typeof value === 'object') {
                    //@ts-ignore
                    valid = (value.length > 0);
                }
                // @ts-ignore
                validationObject[key].error = !valid;
                result = result && valid;
            }
        });
        setValidation(validationObject);
        return result;
    };

    const sendData = () => {
        if (isValid()) {
            let tmp = {
                id: filteredData[currentId - 1]["id"],
                status: filteredData[currentId - 1]["status"],
                comment: filteredData[currentId - 1]["comment"],
                url: filteredData[currentId - 1]["url"],
                printIsActive: filteredData[currentId - 1]["printIsActive"],
                printFrom: filteredData[currentId - 1]["printFrom"],
                printTo: filteredData[currentId - 1]["printTo"],
                socialMediaIsActive: filteredData[currentId - 1]["socialMediaIsActive"],
                socialMediaFrom: filteredData[currentId - 1]["socialMediaFrom"],
                socialMediaTo: filteredData[currentId - 1]["socialMediaTo"],
                careerWebsiteIsActive: filteredData[currentId - 1]["careerWebsiteIsActive"],
                careerWebsiteFrom: filteredData[currentId - 1]["careerWebsiteFrom"],
                careerWebsiteTo: filteredData[currentId - 1]["careerWebsiteTo"]
            };

            fetch(`${process.env.REACT_APP_API_ENDPOINT}/position/update`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(tmp),
                method: 'POST'
            })
                .then(response => response.json())
                .then(() => history.push({
                    pathname: "/positions"
                }))
                .catch((error: any) => {
                    console.log(error);
                });
        }/* else {
            console.log("Invalid form!");
        }*/
    };

    const DashboardPaginationWrapper = (
        {
            rowsPerPage,
            rowCount,
            currentPage,
            direction,
            paginationRowsPerPageOptions,
            paginationIconLastPage,
            paginationIconFirstPage,
            paginationIconNext,
            paginationIconPrevious,
            paginationComponentOptions,
            onChangeRowsPerPage,
            onChangePage
        }: any
    ) => {
        return <DashboardPagination
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            currentPage={currentPage}
            direction={direction}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            paginationIconLastPage={paginationIconLastPage}
            paginationIconFirstPage={paginationIconFirstPage}
            paginationIconNext={paginationIconNext}
            paginationIconPrevious={paginationIconPrevious}
            paginationComponentOptions={paginationComponentOptions}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            onExportAction={onExportAction}
        />
    };
    return (
        <div style={{padding: '3rem 4rem'}} className={"list-wrapper " + (useDashboardHeader ? '' : '_no-padding')}>
            {/*//TODO Make a component from alert*/}
            {validation.channels.error &&
            <div style={{justifyContent: 'start'}} className="alert alert-primary" role="alert">
                <span>Bitte wähle einen Kanal aus!</span>
            </div>
            }
            {(validation.printFrom.error || validation.socialMediaFrom.error || validation.careerWebsiteFrom.error)
            && !validation.channels.error &&
            <div style={{justifyContent: 'start'}} className="alert alert-primary" role="alert">
                <span>Bitte gebe ein Aktivierungsdatum an!</span>
            </div>
            }
            {showDatatable && (
                <div className={"__register-wrapper"}>
                    <div style={0 !== currentId ? {
                        borderBottom: '1px solid #E3E3E3',
                        marginBottom: '1.5rem'
                    } : {marginBottom: '1.5rem'}} className={"__info-wrapper"}>
                        <div className={"__header"}>
                            <div className="form-row">
                                <div className="form-column">
                                    {0 !== currentId && <b><span style={{color: '#909090', textTransform: 'uppercase'}}>
                                        Unternehmen
                                    </span></b>}
                                    <h2 className={"__headline"}>
                                        {0 === currentId ? "Stellenübersicht" : "Berufsbezeichnung"}
                                    </h2>
                                </div>
                                {0 !== currentId &&
                                <a href="#">
                                    <button type="button" id="btn-step-back" className="btn btn-primary"
                                            onClick={sendData}
                                        /*disabled*/>
                                        <span className="text">speichern</span>
                                        <IconNext
                                            width={20}
                                            height={20}
                                        />
                                    </button>
                                </a>
                                }
                            </div>
                        </div>
                    </div>
                    {0 === currentId &&
                    <div className="list-table table-responsive">
                        <div className={"datatable-loader"} style={{display: (updatingDatatable ? 'flex' : 'none')}}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredData ? filteredData : []}
                            selectableRows={false}
                            highlightOnHover={true}
                            pagination={true}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            clearSelectedRows={clearSelectedRows}
                            paginationComponentOptions={paginationSettings}
                            subHeader
                            subHeaderComponent={DashboardFilterWrapper}
                            paginationComponent={DashboardPaginationWrapper}
                            onRowClicked={row => handleRowClicked(row)}
                            defaultSortFieldId={4}
                            defaultSortAsc={false}
                        />
                    </div>}
                    {0 !== currentId &&
                    <OccupationOverview validation={validation} data={filteredData[currentId - 1]}
                                        handler={handleChange} />}
                </div>
            )}
        </div>
    );
}
