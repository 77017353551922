import React, { FC } from "react";
import { ReactComponent as IconNext } from "../../styles/icons/chevron-right.svg";

interface Props {
    active: boolean,
    onToggle?: (style: any) => void,
    handler?: () => void,
    style: any,
    icon: any,
    label?: string
}

const StyleButton: FC<Props> = ({label, active, onToggle, style, icon, handler}) => {
    let className = 'btn btn-light RichEditor-styleButton';
    if (active) {
        className += ' RichEditor-activeButton';
    }

    const localOnToggle = (e: any): any => {
        e.preventDefault();
        onToggle && onToggle(style);
    };

    return (
        <button className={className} onMouseDown={handler ? handler : localOnToggle}>
            {icon}
            {/*<span style={{ top: '-4px', position: 'relative' }} className="text" />*/}
        </button>
    );
}

export default StyleButton;