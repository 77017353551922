import React, { FC } from "react";
import InputComponent from "./InputComponent";
import DropdownComponent from "./DropdownComponent";
import { companies } from "../../dummyData/companies";
import StepButton from "../ui/StepButton";

interface Props {
    handler: React.EventHandler<any>,
    globalState: any,
    validation: any
}

const CompanyForm: FC<Props> = ({handler, globalState, validation}) => {
    const companyNumbers: any[] = [];
    Object.values(companies).forEach((object: any) => {
        companyNumbers.push(object.number);
    });

    const handleCompanyChange = (e: any) => {
        handler(e);

        let companyName = "";
        companies.forEach((object: any) => {
            if (object.number === e.target.value) {
                companyName = object.name;
            }
        });

        handler({
            target: {
                name: "companyName",
                value: companyName,
                type: "dropdown"
            }
        })
    };

    return (
        <div className={"__register-wrapper"}>
            <div className={"row"}>
                <div className={"col-12 col-md-6 col-lg-7"}>
                    <div className={"__info-wrapper"}>
                        <div className={"__header"}>
                            <h3 className={"__headline"}>
                                Angaben zum Unternehmen
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-header-title">
                        Unternehmen
                    </h4>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-column form-container">
                            <DropdownComponent
                                tabIndex={1}
                                isValid={!validation.companyNumber.error}
                                title="Betriebsnummer"
                                name="companyNumber"
                                value={globalState.companyNumber}
                                handler={handleCompanyChange}
                                isRequired={validation.companyNumber.required}
                                options={companyNumbers}
                            />
                        </div>
                        <div className="form-column form-container" style={{borderLeft: '1px solid rgba(0,0,0,0.09)'}}>
                            <InputComponent
                                tabIndex={-1}
                                disabled
                                isValid={!validation.companyName.error}
                                title="Unternehmen"
                                name="companyName"
                                value={globalState.companyName}
                                isRequired={validation.companyName.required}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-header-title">
                        Kontakt
                    </h4>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-column form-container">
                            <InputComponent
                                tabIndex={2}
                                isValid={!validation.firstName.error}
                                title="Vorname"
                                name="firstName"
                                value={globalState.firstName}
                                handler={handler}
                                isRequired={validation.firstName.required}
                            />
                            <InputComponent
                                tabIndex={4}
                                isValid={!validation.email.error}
                                title="Mailadresse"
                                name="email"
                                value={globalState.email}
                                handler={handler}
                                isRequired={validation.email.required}
                                type="email"
                            />
                        </div>
                        <div className="form-column form-container" style={{borderLeft: '1px solid rgba(0,0,0,0.09)'}}>
                            <InputComponent
                                tabIndex={3}
                                isValid={!validation.lastName.error}
                                title="Nachname"
                                name="lastName"
                                value={globalState.lastName}
                                handler={handler}
                                isRequired={validation.lastName.required}
                            />
                            <InputComponent
                                tabIndex={5}
                                isValid={!validation.phoneNumber.error}
                                title="Telefon"
                                name="phoneNumber"
                                value={globalState.phoneNumber}
                                handler={handler}
                                isRequired={validation.phoneNumber.required}
                                type="number"
                                charLimit={20}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <StepButton alignment="Right" type="next" handler={handler} step={globalState.step} />
        </div>
    );
}

export default CompanyForm;
