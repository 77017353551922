import React, { FC } from "react";

interface Props {
    name: string,
    handler?: React.EventHandler<any>,
    title?: string,
    isRequired?: boolean,
    value?: string,
    fullWidth?: boolean,
    placeholder?: string,
    style?: any,
    type?: string,
    isValid?: boolean,
    disabled?: boolean,
    charLimit?: number,
    minDate?: any,
    tabIndex?: number
}

const InputComponent: FC<Props> = (
    {
        name,
        handler,
        title,
        isRequired,
        value,
        fullWidth,
        placeholder,
        style,
        type,
        isValid,
        disabled,
        charLimit,
        minDate,
        tabIndex
    }
) => {
    if (typeof charLimit === "undefined") {
        charLimit = 3;
    }
    let classes = "form-control";
    if (typeof isValid === "undefined") {
        isValid = true;
    } else {
        if (!isValid) {
            classes += " is-invalid";
        }
    }

    if (typeof style === 'undefined') {
        style = {marginBottom: '1rem'};
    } else {
        style = {
            marginBottom: '1rem',
            ...style
        }
    }
    if (fullWidth) {
        style = {
            ...style,
            width: '100%'
        }
    }

    if (!type) {
        type = "text";
    }

    let regex = new RegExp(`^[\+]{0,1}[0-9-/ ]{0,${charLimit}}$`);

    const handleChange = (e: any) => {
        if (type !== "number" || (type === "number" && regex.test(e.target.value))) {
            handler && handler(e);
        }
    };

    let inputStyles = {};
    if (type === "date") {
        if (disabled) {
            inputStyles = {
                ...inputStyles,
                color: 'grey'
            }
        }
        inputStyles = {
            ...inputStyles,
            padding: '0 0.5rem'
        }
    }

    return (
        <div style={style} className="form-column">
            <label className={!isValid ? "is-invalid" : ""} htmlFor={name}>
                {title} {isRequired ? '*' : ''}
            </label>
            <input
                tabIndex={tabIndex}
                disabled={disabled}
                style={inputStyles}
                onChange={handleChange}
                type={type !== "number" ? type : "text"}
                value={value}
                className={classes}
                id={name}
                name={name}
                placeholder={placeholder}
                min={minDate ? minDate : ""}
            />
        </div>
    );
}

export default InputComponent;
