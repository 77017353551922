// States' definition
export interface AppInterface {
    navigation: any,
    companyFilter: string,
    occupationFilter: string
}

const initialState = {
    navigation: null,
    companyFilter: "",
    occupationFilter: ""
};

const appReducer = (state: AppInterface = initialState, action: any): AppInterface => {
    switch (action.type) {
        case 'SET_NAVIGATION':
            return {...state, navigation: action.menu};

        case 'SET_COMPANY_FILTER':
            return {...state, companyFilter: action.companyFilter};

        case 'SET_OCCUPATION_FILTER':
            return {...state, occupationFilter: action.occupationFilter}

        default:
            return state
    }
};

export default appReducer;
