import React, { useState } from "react";
import CompanyForm from "../components/Form/CompanyForm";
import PositionForm from "../components/Form/PositionForm";
import SummaryForm from "../components/Form/SummaryForm";
import Modal from "react-modal";
import StepButton from "../components/ui/StepButton";
import { useSelector } from "react-redux";
import { State } from "../redux/reducer";
import { requirements } from "../dummyData/requirements";
import { benefits } from "../dummyData/benefits";
import { ReactComponent as IconSuccess } from "../styles/icons/SuccessIcon.svg";

type ValidationObjectItem = {
    required: boolean;
    error: boolean;
}

type ValidationObject = {
    [key: string]: ValidationObjectItem;
};

export default function Index(): JSX.Element {
    const authToken = useSelector((state: State) => state.auth.accessToken);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [initialOptions, setInitialOptions] = useState({
        requirements: [...requirements],
        benefits: [...benefits]
    });

    const stepOneAtts = {
        companyNumber: '',
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    }
    const stepTwoAtts = {
        location: '',
        number: '',
        careerLevel: '',
        occupationName: '',
        domains: [],
        position: '',
        workArea: [],
        requirements: [],
        startDate: '',
        vacationClaims: '',
        contractTypes: [],
        salaries: [],
        bonuses: [],
        benefits: [],
        comment: ''
    }
    const [validation, setValidation] = useState<ValidationObject>({
        companyNumber: {
            required: true,
            error: false
        },
        companyName: {
            required: false,
            error: false
        },
        firstName: {
            required: true,
            error: false
        },
        lastName: {
            required: true,
            error: false
        },
        email: {
            required: true,
            error: false
        },
        phoneNumber: {
            required: true,
            error: false
        },
        location: {
            required: true,
            error: false
        },
        number: {
            required: true,
            error: false
        },
        careerLevel: {
            required: true,
            error: false
        },
        occupationName: {
            required: true,
            error: false
        },
        domains: {
            required: true,
            error: false
        },
        position: {
            required: true,
            error: false
        },
        workArea: {
            required: true,
            error: false
        },
        requirements: {
            required: true,
            error: false
        },
        startDate: {
            required: true,
            error: false
        },
        vacationClaims: {
            required: true,
            error: false
        },
        contractTypes: {
            required: true,
            error: false
        },
        salaries: {
            required: true,
            error: false
        },
        bonuses: {
            required: true,
            error: false
        },
        benefits: {
            required: true,
            error: false
        },
        comment: {
            required: false,
            error: false
        }
    });

    const [state, setState] = useState({
        ...stepOneAtts,
        ...stepTwoAtts,
        step: 1
    });
    const {step} = state;

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };

    const handleChange = (e: any) => {
        let currentState: any = {...state};
        let currentValidation = {...validation};
        if (e.target.type === 'checkbox') {
            currentValidation[e.target.name].error = false;
            if (!currentState[e.target.name].includes(e.target.value)) {
                currentState[e.target.name].push(e.target.value);
            } else {
                currentState[e.target.name] = currentState[e.target.name].filter((f: any) => f !== e.target.value);
            }
            setValidation(currentValidation);
            setState(currentState);
        } else if (e.target.type === "button") {
            if (e.target.direction === "back") {
                scrollTo("stepper");
                goToLastStep();
            } else {
                goToNextStep();
            }
        } else {
            if (validation[e.target.name] && validation[e.target.name].required) {
                if (validation[e.target.name].error && e.target.value !== "") {
                    currentValidation[e.target.name].error = false;
                    setValidation(currentValidation);
                }
            }

            setState(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            }));
        }
    };

    const scrollTo = (name = "", positionY = 0) => {
        let y = 0;
        if (positionY > 0) {
            y = positionY;
        } else {
            let element = document.querySelector("#" + name);
            if (!element) {
                element = document.querySelector("[name='" + name + "']");
            }
            if (element) {
                const yOffset = -50;
                y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            }
        }
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    const isValid = (): boolean => {
        let result = true;
        if (step === 1) {
            result = validateVariables(stepOneAtts);
        } else if (step === 2) {
            result = validateVariables(stepTwoAtts);
        }
        return result;
    }

    const validateVariables = (object: any) => {
        let result = true;
        let hasScrolled = false;
        let validationObject = {...validation};
        Object.keys(object).forEach((key: any) => {
            if (validation[key].required) {
                // @ts-ignore
                const value = state[key];
                let valid = true;
                if (typeof value === 'string') {
                    valid = (value !== '');
                } else if (typeof value === 'object') {
                    valid = (value.length > 0);
                }

                if (key === "email") {
                    // @ts-ignore
                    valid = valid && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state[key]);
                }

                validationObject[key].error = !valid;
                if (!valid && !hasScrolled) {
                    scrollTo(key);
                    hasScrolled = true;
                }
                result = result && valid;
            }
        });
        setValidation(validationObject);
        return result;
    };

    const handleSubmit = () => {
        const data: any = {};
        const allAtts = {...stepOneAtts, ...stepTwoAtts};
        Object.keys(allAtts).forEach((key: any) => {
            // @ts-ignore
            data[key] = state[key];
        });
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/position/create`, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            method: 'POST'
        })
            .then((response) => {
                toggleModal();
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const goToNextStep = () => {
        if (isValid() && step < 3) {
            scrollTo("stepper");
            setState(prevState => ({
                ...prevState,
                step: prevState.step + 1
            }));
        }
        if (step === 3) {
            handleSubmit();
            setState(prevState => ({
                ...prevState,
                step: 1
            }));
            setState({
                companyNumber: '',
                companyName: '',
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                location: '',
                number: '',
                careerLevel: '',
                occupationName: '',
                domains: [],
                position: '',
                workArea: [],
                requirements: [],
                startDate: '',
                vacationClaims: '',
                contractTypes: [],
                salaries: [],
                bonuses: [],
                benefits: [],
                comment: '',
                step: 1
            });
        }
    }
    const goToLastStep = () => {
        if (step > 1) {
            setState(prevState => ({
                ...prevState,
                step: prevState.step - 1
            }));
            scrollTo("stepper");
        }
    }

    return (
        <>
            <div className={"__content _hero"}>
                <div className={"__hero-wrapper"}>
                    <div className={"__image"}>
                        <img src="/images/onejob/hero1.png" alt="" />
                    </div>
                    <div className={"__text"}>
                        <div className={"__header"}>
                            <h1 className={"__headline _large"}>Stellen-<br />ausschreib-<br />ungen</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"__content container"}>
                <div className={"__teaser"}>
                    <p style={{paddingRight: '40%'}}>
                        Stellenausschreibungen leicht gemacht: Bitte nutzen Sie das vorliegende
                        Formular, um die Besetzung vakanter Stellen an Ihrem Standort einzuleiten.
                        Darin finden sich alle relevanten Angaben zu Stellenbeschreibung. Bitte
                        folgen Sie ganz einfach den vorgegebenen Schritten beim Ausfüllen des
                        Formulars, bis Ihnen die erfolgreiche Versandbestätigung angezeigt wird.
                    </p>
                </div>
            </div>
            <div className="company-requests-steps-wrapper container">
                <StepButton position="TOP" type="back" handler={goToLastStep} step={step} alignment="Left" />
                <div className="company-requests-steps">
                    <ul className="steps">
                        <li className={step === 1 ? " _active" : ""}>Angaben zum Unternehmen</li>
                        <li className={step === 2 ? " _active" : ""}>Angaben zur Stelle</li>
                        <li className={step === 3 ? " _active" : ""}>Prüfen</li>
                    </ul>
                </div>
                <StepButton position="TOP" type="next" handler={goToNextStep} step={step} alignment="Right" />
            </div>
            <div className={"container"}>
                <div className={"__content"}>
                    {step === 1 &&
                    <CompanyForm
                        validation={validation}
                        globalState={state}
                        handler={handleChange}
                    />}
                    {step === 2 &&
                    <PositionForm
                        initialOptions={initialOptions}
                        validation={validation}
                        globalState={state}
                        handler={handleChange}
                    />}
                    {step === 3 &&
                    <SummaryForm
                        globalState={state}
                        displayTitle
                        handler={handleChange}
                    />}
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="successModal"
                style={{
                    overlay: {zIndex: 999, backgroundColor: '#00000054'},
                    content: {
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'white'
                    }
                }}
            >
                <div style={{padding: '2rem 4rem 2rem 2rem', borderBottom: '1px solid #F5F5F5', flexWrap: 'unset'}} className="form-row">
                    <IconSuccess width={64} height={64} />
                    <p style={{margin: '0 0 0 2.5rem'}} className={"__thx-text"}>
                        <b>Vielen Dank.<br />Ihr Stellenbedarf wurde weitergeleitet!</b>
                    </p>
                </div>
                <p style={{padding: '1.5rem 0 2.5rem 8.5rem', marginBottom: 0}}>
                    Sie erhalten in den <b>kommenden Tagen</b> alle relevanten<br />
                    Informationen zum Status Ihrer Stellenausschreibung/en.
                </p>
                <button onClick={toggleModal} style={{
                    background: 'transparent',
                    position: "absolute" as "absolute",
                    top: 10,
                    right: 10,
                    border: 'none'
                }}>
                    <img src="/images/heroicons-outline/x.svg" style={{height: '20px'}} alt="" />
                </button>
            </Modal>
        </>
    );
}
