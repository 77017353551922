import React, { FC } from "react";
import moment from "moment";
import CheckboxGroup from "./CheckboxGroup";
import StepButton from "../ui/StepButton";
import DisplayComponent from "../ui/DisplayComponent";

interface Props {
    globalState: any,
    displayTitle?: boolean,
    handler?: React.EventHandler<any>,
}

const SummaryForm: FC<Props> = ({globalState, displayTitle, handler}) => {
    return (
        <div className={"__register-wrapper"}>
            {displayTitle &&
            <div className={"row"}>
                <div className={"col-12 col-md-6 col-lg-7"}>
                    <div className={"__info-wrapper"}>
                        <div className={"__header"}>
                            <h3 className={"__headline"}>
                                Prüfen
                            </h3>
                        </div>
                    </div>
                </div>
            </div>}
            <div className="card">
                <div className="card-header">
                    <h4 className="card-header-title">
                        Unternehmensdaten
                    </h4>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-column">
                            <DisplayComponent
                                title="Betriebsnummer"
                                value={globalState.companyNumber}
                            />
                            <DisplayComponent
                                title="Unternehmen"
                                value={globalState.companyName}
                            />
                            <DisplayComponent
                                title="Vor- und Nachname"
                                value={globalState.firstName + " " + globalState.lastName}
                            />
                        </div>
                        <div className="form-column form-container">
                            <DisplayComponent
                                title="Mailadresse"
                                value={globalState.email}
                            />
                            <DisplayComponent
                                title="Telefon"
                                value={globalState.phoneNumber}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-header-title">
                        Stellenprofil
                    </h4>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-column">
                            <DisplayComponent
                                title="Standort"
                                value={globalState.location}
                            />
                            <DisplayComponent
                                title="Berufsbezeichnung"
                                value={globalState.occupationName}
                            />
                            <DisplayComponent
                                title="Berufsgruppe"
                                value={globalState.position}
                            />
                            <DisplayComponent
                                title="Karrierestufe"
                                value={globalState.careerLevel}
                            />
                            <DisplayComponent
                                title="Arbeitsbereich"
                                value={globalState.workArea}
                            />
                        </div>
                        <div className="form-column form-container">
                            <CheckboxGroup
                                style={{marginBottom: '1rem'}}
                                disabled
                                checked
                                options={globalState.domains}
                                title="Einsatzgebiet"
                            />
                            <CheckboxGroup
                                disabled
                                checked
                                options={globalState.requirements}
                                title="Anforderungen"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-header-title">
                        Vertragsbezogene Daten
                    </h4>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-column">
                            <DisplayComponent
                                title="Beginn"
                                value={globalState.startDate}
                            />
                            <CheckboxGroup
                                style={{marginBottom: '1rem'}}
                                isRequired
                                disabled
                                checked
                                options={globalState.contractTypes}
                                title="Vertragsart"
                                isSummary
                            />
                            <CheckboxGroup
                                style={{marginBottom: '1rem'}}
                                isRequired
                                disabled
                                checked
                                options={globalState.salaries}
                                title="Gehalt"
                                isSummary
                            />
                            <CheckboxGroup
                                isRequired
                                disabled
                                checked
                                options={globalState.bonuses}
                                title="Boni-Zahlungen"
                                isSummary
                            />
                            <DisplayComponent
                                title="Urlaubsanpruch in Tagen"
                                value={globalState.vacationClaims}
                            />
                        </div>
                        <div className="form-column form-container">
                            <CheckboxGroup
                                isRequired
                                disabled
                                checked
                                options={globalState.benefits}
                                title="Benefits"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {handler &&
            <div className="form-row">
                <StepButton alignment="Left" type="back" handler={handler} step={globalState.step} />
                <StepButton alignment="Right" type="next" handler={handler} step={globalState.step} />
            </div>
            }
        </div>
    );
}

export default SummaryForm;
