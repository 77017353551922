import React, { FC } from "react";
import { ReactComponent as IconNext } from "../../styles/icons/chevron-right.svg";
import { ReactComponent as IconPrevious } from "../../styles/icons/chevron-left.svg";

interface Props {
    type: string,
    handler: React.EventHandler<any>,
    step?: number,
    alignment?: string,
    tabIndex?: number,
    position?: string
}

const StepButton: FC<Props> = ({type, handler, step, alignment, position, tabIndex}) => {
    let classes = "button-container";
    classes += (alignment ? " justify" + alignment : "");

    const handleClick = (e: any) => {
        handler({
            target: {
                type: "button",
                direction: type
            }
        });
    };

    return (
        <div className={classes}>
            <a>
                {type === "next" &&
                <button
                    tabIndex={tabIndex}
                    name={position && position === "TOP" ? "stepper" : ""}
                    type="button"
                    id="btn-step"
                    onClick={handleClick}
                    className="btn btn-step btn-primary"
                >
                    <span className="text">{step === 3 ? 'absenden' : 'weiter'}</span>
                    <IconNext
                        style={{marginTop: '-3px'}}
                        width={20}
                        height={20}
                    />
                </button>}
                {type === "back" &&
                <button
                    tabIndex={tabIndex}
                    name={position && position === "TOP" ? "stepper" : ""}
                    type="button"
                    id="btn-step-back"
                    className="btn btn-white"
                    onClick={handleClick}
                    disabled={step === 1}
                >
                    <IconPrevious
                        style={{marginTop: '-3px'}}
                        width={20}
                        height={20}
                    />
                    <span className="text">zurück</span>
                </button>}
            </a>
        </div>
    );
}

export default StepButton;