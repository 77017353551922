export const careerLevels = [
    'Praktikum',
    'Ausbildung',
    'Duales Studium',
    '---',
    'Berufseinsteiger',
    'Berufserfahrener',
    '---',
    'Associate',
    'Mittleres Management',
    'Direktor',
    'Geschäftsführer',
    '---',
    'nicht zutreffend'
];