import React, { FC } from "react";
import SummaryForm from "../../components/Form/SummaryForm";
import DropdownComponent from "../../components/Form/DropdownComponent";
import { STATUS_STATES } from "./Columns";
import CheckboxGroup from "../../components/Form/CheckboxGroup";
import EditorComponent from "../../components/ui/EditorComponent";
import InputComponent from "../../components/Form/InputComponent";
import moment from "moment";

interface Props {
    data: any,
    handler?: any,
    validation: any
}

const OccupationOverview: FC<Props> = ({data, handler, validation}) => {
    return (
        <div style={{padding: 0}} className={"container"}>
            <div className={"__content"}>
                <div className="card">
                    <div style={{padding: '1rem 2rem', height: 'unset'}} className="card-header">
                        <div className="form-column">
                            <DropdownComponent
                                options={Object.values(STATUS_STATES)}
                                title="Status"
                                name="status"
                                handler={handler}
                                value={data.status}
                                style={{width: 'fit-content'}}
                                noDefaultOption
                            />
                        </div>
                    </div>
                    <div className="card-body" style={{paddingTop: '1rem'}}>
                        <div className="form-row" style={{justifyContent: 'flex-start'}}>
                            <CheckboxGroup
                                values={[data.printIsActive, data.socialMediaIsActive, data.careerWebsiteIsActive]}
                                options={["Print", "social media", "Karrierewebseite"]}
                                name={["printIsActive", "socialMediaIsActive", "careerWebsiteIsActive"]}
                                style={{width: '25%'}}
                                isRequired={data.status === STATUS_STATES.public}
                                title="Kanäle"
                                handler={handler}
                                asSwitch
                                isValid={!validation.channels.error}
                            />
                            <div className="form-row" style={{marginLeft: '2.5rem', width: '65%', justifyContent: 'unset'}}>
                                <div className="form-column" style={{width: '30%'}}>
                                    <InputComponent
                                        style={{maxHeight: '60px', marginBottom: 0}}
                                        title="aktiv ab"
                                        name="printFrom"
                                        fullWidth
                                        placeholder="TT.MM.JJJJ"
                                        type="date"
                                        value={data.printFrom}
                                        handler={handler}
                                        disabled={!data.printIsActive}
                                        isValid={!validation.printFrom.error}
                                        minDate={moment(new Date()).format("YYYY-MM-DD")}
                                    />
                                    <InputComponent
                                        style={{maxHeight: '36px', marginBottom: 0}}
                                        name="socialMediaFrom"
                                        fullWidth
                                        placeholder="TT.MM.JJJJ"
                                        type="date"
                                        value={data.socialMediaFrom}
                                        handler={handler}
                                        disabled={!data.socialMediaIsActive}
                                        isValid={!validation.socialMediaFrom.error}
                                        minDate={moment(new Date()).format("YYYY-MM-DD")}
                                    />
                                    <InputComponent
                                        style={{maxHeight: '36px', marginBottom: 0}}
                                        name="careerWebsiteFrom"
                                        fullWidth
                                        placeholder="TT.MM.JJJJ"
                                        type="date"
                                        value={data.careerWebsiteFrom}
                                        handler={handler}
                                        disabled={!data.careerWebsiteIsActive}
                                        isValid={!validation.careerWebsiteFrom.error}
                                        minDate={moment(new Date()).format("YYYY-MM-DD")}
                                    />
                                </div>
                                <div className="form-column" style={{width: '30%', marginLeft: '2rem'}}>
                                    <InputComponent
                                        style={{maxHeight: '60px', marginBottom: 0}}
                                        title="aktiv bis (optional)"
                                        name="printTo"
                                        fullWidth
                                        type="date"
                                        value={data.printTo}
                                        handler={handler}
                                        placeholder="TT.MM.JJJJ"
                                        disabled={!data.printIsActive || !data.printFrom || data.printFrom === ""}
                                        minDate={data.printFrom}
                                    />
                                    <InputComponent
                                        style={{maxHeight: '36px', marginBottom: 0}}
                                        name="socialMediaTo"
                                        fullWidth
                                        placeholder="TT.MM.JJJJ"
                                        type="date"
                                        value={data.socialMediaTo}
                                        handler={handler}
                                        disabled={!data.socialMediaIsActive || !data.socialMediaFrom || data.socialMediaFrom === ""}
                                        minDate={data.socialMediaFrom}
                                    />
                                    <InputComponent
                                        style={{maxHeight: '36px', marginBottom: 0}}
                                        name="careerWebsiteTo"
                                        fullWidth
                                        placeholder="TT.MM.JJJJ"
                                        type="date"
                                        value={data.careerWebsiteTo}
                                        handler={handler}
                                        disabled={!data.careerWebsiteIsActive || !data.careerWebsiteFrom || data.careerWebsiteFrom === ""}
                                        minDate={data.careerWebsiteFrom}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{marginBottom: '1rem'}} className="form-row">
                            <EditorComponent
                                handler={handler}
                                title="Kommentar (optional)"
                                value={data.comment}
                            />
                        </div>
                        <div className="form-row">
                            <InputComponent
                                title="url hinzufügen (optional)"
                                name="url"
                                fullWidth
                                value={data.url}
                                handler={handler}
                            />
                        </div>
                    </div>
                </div>
                <SummaryForm globalState={data} />
            </div>
        </div>
    );
}

export default OccupationOverview;