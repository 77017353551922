export const occupationTypes = {
    INDUSTRY: 'Bau & Handwerk',
    BUSINESS: 'Kaufmännische Berufe',
    TECHNICAL: 'Technische Berufe',
    LOGISTICS: 'Transport & Logistik'
}

export const occupations: any = [
    {
        name: 'Abrechner',
        path: '/pdf/Abrechner.pdf',
        group: occupationTypes.BUSINESS
    },
    {
        name: 'Bauleiter',
        path: '/pdf/Bauleiter.pdf',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Einkäufer',
        path: '/pdf/Einkäufer.pdf',
        group: occupationTypes.BUSINESS
    },
    {
        name: 'Kalkulator',
        path: '/pdf/Kalkulator.pdf',
        group: occupationTypes.BUSINESS
    },
    {
        name: 'Leiter kaufmännische Abteilung',
        path: '/pdf/Leiter_kaufmännische_Abteilung.pdf',
        group: occupationTypes.BUSINESS
    },
    {
        name: 'Leiter technisches Büro',
        path: '/pdf/Leiter_technisches_Büro.pdf',
        group: occupationTypes.TECHNICAL
    },
    {
        name: 'Oberbauleiter',
        path: '/pdf/Oberbauleiter.pdf',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Polier',
        path: '/pdf/Polier_Schachtmeister.pdf',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Schachtmeister',
        path: '/pdf/Polier_Schachtmeister.pdf',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Vermesser',
        path: '/pdf/Vermesser.pdf',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Gleisbauer',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Baugerätführer',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Fachkraft für Straßen- und Verkehrstechnik',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Zimmerer',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Ausbaufacharbeiter',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Rohrleitungsbauer',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Kanalbauer',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Bauingenieure',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Elektroniker für Betriebstechnik',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Land- und Baumaschinenmechatroniker',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Straßenbauer',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Asphaltbauer',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Tiefbaufacharbeiter',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Spezialtiefbauer',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Hochbaufacharbeiter',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Monteur',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Industriemechaniker',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Baugeräteverwalter',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Beton- und Stahlbetonbauer',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Maurer',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Bauwerksabdichter',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Verfahrensmechaniker',
        path: '',
        group: occupationTypes.INDUSTRY
    },
    {
        name: 'Industriekaufmann',
        path: '',
        group: occupationTypes.BUSINESS
    },
    {
        name: 'Kaufmann für Büromanagement',
        path: '',
        group: occupationTypes.BUSINESS
    },
    {
        name: 'Kaufmann im Groß- und Außenhandel',
        path: '',
        group: occupationTypes.BUSINESS
    },
    {
        name: 'Technischer Einkäufer',
        path: '',
        group: occupationTypes.BUSINESS
    },
    {
        name: 'Bauabrechner',
        path: '',
        group: occupationTypes.BUSINESS
    },
    {
        name: 'Bauzeichner',
        path: '',
        group: occupationTypes.TECHNICAL
    },
    {
        name: 'Vermessungstechniker',
        path: '',
        group: occupationTypes.TECHNICAL
    },
    {
        name: 'Berufskraftfahrer',
        path: '',
        group: occupationTypes.LOGISTICS
    },
    {
        name: 'Vorarbeiter',
        path: '',
        group: occupationTypes.INDUSTRY
    }
]

