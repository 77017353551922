export const workAreas = [
    'Hochbau',
    'Tiefbau',
    'Verkehrssicherung',
    'Ingenieurbau',
    'Gussasphalt',
    'Hoch- und Tiefbau',
    'Kabelleitungsbau',
    'Brückenbau',
    'Wasserbau',
    'Gleisbau',
    'Kanalbau',
    'Straßenbau',
    'Rohrleitungsbau',
    'Bauarbeitsgemeinschaften'
];