import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../redux/actions';
import { State } from '../redux/reducer';

export default function Login(): JSX.Element {
    const dispatch = useDispatch();
    const auth = useSelector((state: State) => state.auth);

    const [state, setState] = useState({
        email: '',
        password: '',
    });

    const handleParam = (e: React.FormEvent<HTMLInputElement>) => {
        const {id, value} = e.target as HTMLTextAreaElement;
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(loginUser(state.email, state.password));
    };

    return (
        <div className={"container"}>
            <div className="logo">
                <img src="/images/onejob/OneJobLogo.png" alt={"Matthäi"} />
            </div>
            <div className="login">
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title">
                            Anmelden
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            <form className="form _login" name="form-login"
                                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
                                <div className="form-row">
                                    <div style={{flexGrow: 1, marginBottom: '1rem'}} className="form-column">
                                        <label htmlFor="input-user"><b>Benutzer</b></label>
                                        <input className="form-control" style={{width: '100%'}}
                                               id="email"
                                               required
                                               type='text'
                                               name='email'
                                               value={state.email}
                                               onChange={(e: React.FormEvent<HTMLInputElement>) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: (auth.loginError === null ? 'none' : 'block')}}>
                                            Der eingegebene Benutzername ist falsch.
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div style={{flexGrow: 1, marginBottom: '1rem'}} className="form-column">
                                        <div className="input-wrapper">
                                            <label htmlFor="input-password"><b>Passwort</b></label>
                                            <div className="forgot-password">
                                                <a href="#!" tabIndex={-1}>
                                                    Passwort vergessen
                                                </a>
                                            </div>
                                        </div>
                                        <input className="form-control"
                                               id="password"
                                               name="password"
                                               onChange={(e: React.FormEvent<HTMLInputElement>) => handleParam(e)}
                                               required
                                               type="password"
                                               value={state.password}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: (auth.loginError === null ? 'none' : 'block')}}>
                                            Das eingegebene Passwort ist falsch.
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row" style={{marginBottom: '1rem'}}>
                                    <div className="custom-control custom-checkbox">
                                        <input className="custom-control-input" type="checkbox" id="checkbox-login" />
                                        <label className="custom-control-label" htmlFor="checkbox-login">Angemeldet
                                            bleiben</label>
                                    </div>
                                </div>
                                <div className="__submit">
                                    <button id="login-submit" type="submit" className="btn btn-primary"
                                            value="submit">Anmelden
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
