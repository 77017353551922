import "./styles/globals.scss";
import 'react-toastify/dist/ReactToastify.css';
import 'draft-js/dist/Draft.css';

import React from 'react';
import PropTypes from 'prop-types';
import { persistor, store } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { Router } from 'react-router-dom';
import history from './redux/history';
import RouterComponent from "./components/navigation/RouterComponent";

function App({Component, pageProps}: any) {
    return (
        <Router history={history}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    {/*<ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />*/}
                    <RouterComponent />
                    <div className="footer">
                        <img src="/images/logos/wortmarke_matthaei.png" alt={"Matthäi"} />
                    </div>
                </PersistGate>
            </Provider>
        </Router>
    )
}

App.propTypes = {
    Component: PropTypes.any,
    pageProps: PropTypes.any
};

export default App;
