export const companies = [
    {
        number: "001 - Matthäi Bremervörde",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "003 - Matthäi Transportbeton",
        name: "Matthäi Transportbeton GmbH & Co. KG"
    },
    {
        number: "004 - Matthäi Schaumburg",
        name: "Matthäi Schaumburg GmbH & Co. KG"
    },
    {
        number: "005 - Bartel-Bau",
        name: "Bartel-Bau GmbH & Co. Betriebs-KG"
    },
    {
        number: "006 - Matthäi Bremen",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "006.1 - Matthäi Dinslaken",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "007 - Matthäi Westerstede",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "008 - Matthäi Berlin",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "009 - Matthäi Verden",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "010 - Matthäi Langenhagen",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "011 - Regrata",
        name: "Regrata Abbruch und Recycling GmbH & Co. KG"
    },
    {
        number: "012 - Matthäi Schlüsselfertigbau",
        name: "Matthäi Schlüsselfertigbau GmbH & Co. KG"
    },
    {
        number: "017 - Druve Straßenbau GmbH",
        name: "Druve Straßenbau GmbH"
    },
    {
        number: "018 - Matthäi Asfalttechnik GmbH",
        name: "Matthäi Asfalttechnik GmbH"
    },
    {
        number: "019 - Matthäi Rohstoff",
        name: "Matthäi Rohstoff"
    },
    {
        number: "021 - Matthäi Freienhufen",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "022 - Matthäi Wasserbau",
        name: "Matthäi Wasserbau GmbH & Co. KG"
    },
    {
        number: "023 - M-Asphalt Freienhufen",
        name: "M-Asphalt Freieinhufen Zweigniederlassung der Matthäi Rohstoff GmbH & Co. KG, Verden"
    },
    {
        number: "024 - Havelbeton",
        name: "Havelbeton GmbH & Co. KG"
    },
    {
        number: "025 - Baustoffe Flechtingen",
        name: "Baustoffe Flechtingen Zweigniederlassung der Matthäi Rohstoff GmbH & Co. KG, Verden"
    },
    {
        number: "025.1 - Baust. Flecht. Brößnitz",
        name: "Baustoffe Flechtingen Zweigniederlassung der Matthäi Rohstoff GmbH & Co. KG, Verden"
    },
    {
        number: "026 - M-Asphalt Gardelegen",
        name: "M-Asphalt Gardelegen Zweigniederlassung der Matthäi Rohstoff GmbH & Co. KG, Verden"
    },
    {
        number: "027 - Floating Homes",
        name: "Floating Homes GmbH"
    },
    {
        number: "028 - MMS Transport und Umwelt GmbH",
        name: "MMS Transport und Umwelt GmbH"
    },
    {
        number: "029 - Matthäi Stendal",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "030 - M-Asphalt Oranienburg - Mischwerk",
        name: "M-Asphalt Oranienburg Zweigniederlassung der Matthäi Rohstoff GmbH & Co. KG, Verden"
    },
    {
        number: "032 - Matthäi Betriebsführungs GmbH",
        name: "Matthäi Betriebsführungs GmbH"
    },
    {
        number: "034 - M-TEC",
        name: "M-TEC GmbH & Co. KG"
    },
    {
        number: "035 - ADG Abfallbearbeitungs-",
        name: "ADG Abfallbearbeitungsund Dienstleistungsgesellschaft mbH"
    },
    {
        number: "037 - Matthäi Leezen",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "040 - Matthäi Trimodalbau",
        name: "Matthäi Trimodalbau GmbH & Co. KG"
    },
    {
        number: "041 - Matthäi Velten",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "041.1 - Matthäi Michendorf",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "044 - M-Asphalt Alteno",
        name: "M-Asphalt Alteno Zweigniederlassung der Matthäi Rohstoff GmbH & Co. KG, Verden"
    },
    {
        number: "045 - Specht",
        name: "Specht Baustoffhandel, Transport und Entsorgung GmbH & Co. KG"
    },
    {
        number: "046 - Lauer Verkehrstechnik Oldenburg",
        name: "Lauer Verkehrstechnik GmbH"
    },
    {
        number: "047 - Matthäi Magdeburg",
        name: "Matthäi Bauunternehmen GmbH & Co. KG"
    },
    {
        number: "047.2 - Matthäi Halle (Saale)",
        name: "Matthäi Magdeburg Bauunternehmen GmbH & Co. KG NL Halle (Saale)"
    },
    {
        number: "048 - M Stein",
        name: "M Stein, Zweigniederlassung der Matthäi Rohstoff GmbH & Co. KG"
    },
    {
        number: "049 - M-Beton",
        name: "M-Beton GmbH"
    },
    {
        number: "059 - Lauer Verkehrstechnik Alteno",
        name: "Lauer Verkehrstechnik GmbH"
    },
    {
        number: "063 - Lauer Verkehrstechnik GmbH",
        name: "Lauer Verkehrstechnik GmbH"
    },
    {
        number: "065 - M-Entsorgung und Umwelttechnik Lausitz",
        name: "M-Entsorgung und Umwelttechnik Lausitz"
    },
    {
        number: "068 - Galeiwa-Bau",
        name: "Galeiwa-Bau GmbH"
    },
    {
        number: "072 - MIT Service",
        name: "MIT Service GmbH"
    },
    {
        number: "077 - Lauer Verkehrstechnik Beelitz",
        name: "Lauer Verkehrstechnik GmbH Beelitz"
    },
    {
        number: "078 - M-Entsorgung und Umwelttechnik Nord-Elm",
        name: "M-Entsorgung und Umwelttechnik Nord-Elm GmbH"
    },
    {
        number: "082 - Matthäi Lingen Bauunternehmen GmbH",
        name: "Matthäi Lingen Bauunternehmen GmbH"
    },
    {
        number: "083 - Marex Baumaschinen",
        name: "Marex Baumaschinen GmbH"
    },
    {
        number: "085 - M Logistik",
        name: "M Logistik Luckau GmbH"
    },
    {
        number: "093 - Matthäi Wiesbaden",
        name: "Matthäi Bauunternehmen GmbH Wiesbaden"
    },
    {
        number: "096 M - Agrar & Frost",
        name: "Agrar & Frost"
    },
    {
        number: "098 - M Natursteine GmbH",
        name: "M Natursteine Luckau GmbH"
    },
    {
        number: "100 - Otto Hagemann GmbH & Co.",
        name: "Otto Hagemann GmbH & Co. KG"
    },
    {
        number: "103 - Neogy Energiebau",
        name: "Neogy Energiebau GmbH"
    },
    {
        number: "104 - Thüringer Sprenggesellschaft mbH",
        name: "Thüringer Sprenggesellschaft mbH"
    },
    {
        number: "105 - Jungclaus Straßenbau Gmbh & Co.",
        name: "Jungclaus Straßenbau Gmbh & Co"
    },
    {
        number: "106 - Tief- und Rohrleitungsbau Berlin",
        name: "Tief- und Rohrleitungsbau Berlin"
    },
    {
        number: "110 - Evers und Co. GmbH",
        name: "Evers und Co. GmbH"
    },
    {
        number: "176 - Grundstücksgesellschaft Brunnenweg GmbH & Co. KG",
        name: "Grundstücksgesellschaft Brunnenweg GmbH & Co. KG"
    },
    {
        number: "210 - Intrerrock Oy",
        name: "Intrerrock Oy"
    },
    {
        number: "221 - P.P.U. Czernica Granit",
        name: "P.P.U. Czernica Granit Sp. z o.o."
    },
    {
        number: "230 - Matthäi Estland",
        name: "OÜ Matthäi Ehitus"
    },
    {
        number: "231 - OÜ Väo Paas",
        name: "OÜ Väo Paas"
    },
    {
        number: "240 - SIA Matthai",
        name: "SIA Matthai"
    },
    {
        number: "337 - ARGE Bodenbörse Nordwest",
        name: "ARGE Bodenbörse Nordwest"
    }
];