import React, { FC } from "react";
import StyleButton from "./StyleButton";
import { Editor, EditorState, RichUtils, getDefaultKeyBinding } from "draft-js";
import { ReactComponent as BoldIcon } from "../../styles/icons/BoldIcon.svg";
import { ReactComponent as ItalicIcon } from "../../styles/icons/ItalicIcon.svg";
import { ReactComponent as UnderlineIcon } from "../../styles/icons/UnderlineIcon.svg";
import { ReactComponent as ListIcon } from "../../styles/icons/ListIcon.svg";
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

interface Props {
    title?: string,
    style?: any,
    value?: string,
    handler?: React.EventHandler<any>
}

const EditorComponent: FC<Props> = ({title, style, value, handler}) => {
    const [editorState, setEditorState] = React.useState(
        () => value ? EditorState.createWithContent(stateFromHTML(value)) : EditorState.createEmpty(),
    );
    /*const insertSoftNewline = () => {
        const newState = RichUtils.insertSoftNewline(editorState);
        if (newState) {
            setEditorState(newState);
        }
    };*/
    const handleChange = (e: EditorState): void => {
        setEditorState(e);
        if (handler) {
            handler({
                target: {
                    name: "comment",
                    /*value: e.getCurrentContent().getPlainText('\u0001')*/
                    value: stateToHTML(editorState.getCurrentContent())
                }
            });
        }
    };

    const BLOCK_TYPES = [
        {label: 'U', style: 'unordered-list-item', icon: <ListIcon width={40} height={40} />},
    ];

    const INLINE_STYLES = [
        {label: 'B', style: 'BOLD', icon: <BoldIcon width={40} height={40} />},
        {label: 'I', style: 'ITALIC', icon: <ItalicIcon width={40} height={40} />},
        {label: 'Underline', style: 'UNDERLINE', icon: <UnderlineIcon width={40} height={40} />},
    ];

    const BlockStyleControls = (props: any) => {
        const {editorState} = props;
        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        return (
            <div className="RichEditor-controls">
                {BLOCK_TYPES.map((type) =>
                    <StyleButton
                        key={type.label}
                        active={type.style === blockType}
                        label={type.label}
                        icon={type.icon}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                )}
            </div>
        );
    };

    const handleKeyCommand = (command: any, editorState: any): any => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return true;
        }

        return false;
    }
    const mapKeyToEditorCommand = (e: any): any => {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== editorState) {
                setEditorState(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    };

    const toggleBlockType = (blockType: any) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    }

    const toggleInlineStyle = (inlineStyle: any) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    }

    const getBlockStyle = (block: any): any => {
        switch (block.getType()) {
            case 'blockquote':
                return 'RichEditor-blockquote';
            default:
                return null;
        }
    }

    let className = 'RichEditor-editor';
    let contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    const editorRef: any = React.createRef();
    const InlineStyleControls = (props: any) => {
        const currentStyle = props.editorState.getCurrentInlineStyle();

        return (
            <div className="RichEditor-controls">
                {INLINE_STYLES.map((type) =>
                    <StyleButton
                        key={type.label}
                        icon={type.icon}
                        active={currentStyle.has(type.style)}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                )}
            </div>
        );
    };

    return (
        <div style={{width: '100%'}} className="RichEditor-root">
            <label style={style}>{title}</label>
            <div className={className}>
                <div style={{
                    margin: '0.7rem 0 0 0.6rem',
                    width: 'fit-content',
                    display: 'flex',
                    padding: '0.5rem',
                    backgroundColor: '#FCFCFC'
                }}>
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                    />
                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={toggleBlockType}
                    />
                </div>
                <Editor
                    blockStyleFn={getBlockStyle}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={mapKeyToEditorCommand}
                    onChange={handleChange}
                    ref={editorRef}
                    spellCheck={true}
                />
            </div>
        </div>
    );
}

export default EditorComponent;